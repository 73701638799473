const ranges = {
    oneDay: [
        [Number.NEGATIVE_INFINITY, -1.25],
        [-1.25, -0.625],
        [-0.625, -0.001],
        [-0.001, 0],
        [0, 0.625],
        [0.625, 1.25],
        [1.25, Number.POSITIVE_INFINITY]
    ],
    oneMonth: [
        [Number.NEGATIVE_INFINITY, -4.0],
        [-4.0, -2.0],
        [-2.0, -0.001],
        [-0.001, 0],
        [0, 2],
        [2, 4],
        [4, Number.POSITIVE_INFINITY]
    ],
    threeMonth: [
        [Number.NEGATIVE_INFINITY, -8.0],
        [-8.0, -2.667],
        [-2.667, -0.001],
        [-0.001, 0],
        [0, 2.666],
        [2.666, 8.0],
        [8.0, Number.POSITIVE_INFINITY]
    ],
    oneYear: [
        [Number.NEGATIVE_INFINITY, -20.0],
        [-20.0, -6.667],
        [-6.667, -0.001],
        [-0.001, 0],
        [0, 6.667],
        [6.667, 20.0],
        [20.0, Number.POSITIVE_INFINITY]
    ]
};

ranges.oneWeek = ranges.oneDay;
ranges.twoYear = ranges.oneYear;
ranges.threeYear = ranges.oneYear;
ranges.fiveYear = ranges.oneYear;
ranges.tenYear = ranges.oneYear;
ranges.fifteenYear = ranges.oneYear;

export const colorSet = [
    'color-negative',
    'color-negative-40',
    'color-negative-70',
    'color-base',
    'color-positive-70',
    'color-positive-40',
    'color-positive'
];

export function getFillColor(dateRange, value) {
    const range = ranges[dateRange];
    if (range) {
        for (let i = 0; i < range.length; i++) {
            if (value >= range[i][0] && value < range[i][1]) {
                return colorSet[i];
            }
        }
    }
    return colorSet[3];
}

export function getRange(dateRange) {
    const range = ranges[dateRange];
    return [range[0][1], range[range.length - 1][0]];
}
