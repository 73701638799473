<template>
    <markets-ui-container
        :class="cls"
        :autoHeight="watchedSettings.autoHeight"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        @setting-click="toggleSettings"
    >
        <markets-ui-settings
            slot="settings"
            ref="sectorHeatmapUiSettings"
            :skin="watchedSettings.skin"
            :title="initedLabels.settingTitle"
            :doneLabel="initedLabels.done"
            :showBack="!!settingsPopoverNavSelected"
            :showDone="!!settingsPopoverNavSelected"
            :triggered-by="settingsPopoverTriggerId"
            :visible="settingsPopoverVisible"
            @hide="hideSettingsPopover"
            @done="changeDataPoints"
            @back="changeSettingsNav"
        >
            <markets-ui-settings-navigation
                v-if="!settingsPopoverNavSelected"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNav"
                @change="changeSettingsNav"
                slot="content"
            ></markets-ui-settings-navigation>
            <markets-ui-checkbox-group
                v-if="showDataPointsSettting"
                size="small"
                :skin="watchedSettings.skin"
                :dataModel="settingDataPoints"
                @change="changeSettingDataPoints"
                slot="content"
            ></markets-ui-checkbox-group>
        </markets-ui-settings>
        <div slot="content" :class="[namespace('container')]">
            <section v-if="gridRows.length > 0" ref="tableContainer">
                <markets-ui-grid
                    :skin="watchedSettings.skin"
                    :option="gridOptions"
                    :columns="gridColumns"
                    :rows="gridRows"
                    :labels="initedLabels"
                    :caption="initedLabels.sectorHeatmapTitle"
                ></markets-ui-grid>
                <p :class="[namespace('date-reference')]">
                    {{ dataAsOf }}
                </p>
            </section>
        </div>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import { dataPoints, getSectorMeta } from './metadata/datapoint';
import { getFillColor } from './metadata/data-range';
import labels from './assets/labels.json';
const { utils, mixins } = mwcMarketsCore;

export default {
    name: 'mwc-markets-sector-heatmap-ui',
    mixins: [mixins.componentUI],
    props: {
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        },
        showLoading: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        settingsPopoverVisible(val) {
            if (!val) {
                this.settingsPopoverNavSelected = false;
            }
        }
    },
    computed: {
        cls() {
            const cls = [this.namespace()];
            if (this.watchedSettings.skin) {
                cls.push(
                    this.namespace(
                        `-${utils.getSkinSuffix(this.watchedSettings.skin)}`
                    )
                );
            }
            return cls;
        },
        gridOptions() {
            return {
                mobileMode: this.isMobile,
                highlightColumns: ['sector'],
                autoHeight: true
            };
        },
        gridColumns() {
            const columnSet = [];
            dataPoints.forEach(dp => {
                const item = utils.extend(true, {}, dp);
                const enabled =
                    utils.inArray(this.enabledDataPoints, item.id) > -1 ||
                    item.enabled;
                item.name = this.initedLabels[dp.id];
                if (item.id !== 'sector') {
                    item.format = (value, rowData, columns) => {
                        const cls = `${this.namespace(
                            getFillColor(columns.id, value)
                        )} ${this.namespace('color-cell')}`;
                        const formatValue = utils.getFormatValue({
                            formatter: this.formatter,
                            dataType: 'number',
                            value: value
                        });
                        return `<span class="${cls}">${formatValue}</span>`;
                    };
                } else {
                    item.format = (value, rowData, columns) => {
                        const sector = getSectorMeta(rowData.id);
                        if (sector) {
                            return `<markets-ui-icon name="${
                                sector.icon
                            }"/></markets-ui-icon><span class="${this.namespace(
                                'sector-name'
                            )}">${value}</span>`;
                        } else {
                            return utils.emptyString;
                        }
                    };
                }
                if (enabled) {
                    columnSet.push(item);
                }
            });
            return columnSet;
        },
        gridRows() {
            return this.dataModel.map(d => {
                const queryKey = d.queryKey || '';
                const sector = getSectorMeta(queryKey);
                if (!sector) {
                    return {};
                }
                const sectorHtml = this.initedLabels[sector.label];
                const row = {
                    id: sector.id,
                    sector: sectorHtml
                };
                this.gridColumns.forEach(col => {
                    if (col.id === 'sector') {
                        return;
                    }
                    row[col.id] = d[col.id];
                });
                return row;
            });
        },

        /**
         *  order data set for the radio input in mobile screan
         */
        orderSet() {
            return ['ascending', 'descending'].map(id => {
                return {
                    id: id,
                    name: this.initedLabels[id],
                    disabled: false,
                    selected: this.selectedOrder === id
                };
            });
        },
        /**
         *  the last trade date time in the data model set
         */
        dataAsOf() {
            let intraday = {};
            let eod;
            this.dataModel.forEach(item => {
                const lastTradeDateTime = intraday.lastTradeDateTime || '';
                if (item.lastTradeDateTime > lastTradeDateTime) {
                    intraday = item;
                }
                if (!eod) {
                    eod = item.returnEndDate;
                }
            });
            let ret = '';

            const isOnlyIntraday =
                this.enabledDataPoints.length === 1 &&
                this.enabledDataPoints[0] === 'oneDay';
            const isAllEOD =
                utils.inArray(this.enabledDataPoints, 'oneDay') === -1 ||
                !intraday.lastTradeDateTime;

            if (isAllEOD) {
                ret = `${this.initedLabels.dataAsOf} ${utils.getFormatValue({
                    formatter: this.formatter,
                    dataType: 'date',
                    value: eod
                })}`;
            } else if (isOnlyIntraday) {
                ret = `${this.initedLabels.dataAsOf} ${utils.getFormatValue({
                    formatter: this.formatter,
                    dataType: 'dateTime',
                    value: intraday.lastTradeDateTime
                })} ${intraday.timezoneAbbreviation}`;
            } else {
                ret = `${
                    this.initedLabels.intradayDataAsOf
                } ${utils.getFormatValue({
                    formatter: this.formatter,
                    dataType: 'dateTime',
                    value: intraday.lastTradeDateTime
                })} ${intraday.timezoneAbbreviation}, ${
                    this.initedLabels.eodDataAsOf
                } ${utils.getFormatValue({
                    formatter: this.formatter,
                    dataType: 'date',
                    value: eod
                })}.`;
            }
            return ret;
        },

        settingsPopoverNav() {
            if (this.settingDataPoints) {
                return [
                    {
                        id: 'dataPoints',
                        title: this.initedLabels['timePeriod'],
                        name: `${
                            this.settingDataPoints.filter(d => d.selected)
                                .length
                        } ${this.initedLabels['selected']}`
                    }
                ];
            } else {
                return [];
            }
        },
        showDataPointsSettting() {
            return (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'dataPoints'
            );
        }
    },
    methods: {
        changeSettingsNav(item) {
            this.settingsPopoverNavSelected = item;
            this.settingsPopoverTitle = item
                ? item.title
                : this.initedLabels.settingTitle;
            this.settingDataPoints = this._loadSettingDataPoints();
            this.$refs.sectorHeatmapUiSettings.enableTrapFocus();
        },
        changeSettingDataPoints(items) {
            this.settingDataPointsSelected = items;
        },
        toggleSettings(e) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggerId = utils.computeTriggerId(e.target);
            if (this.settingsPopoverVisible) {
                this.settingDataPoints = this._loadSettingDataPoints();
            }
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.settingsPopoverNavSelected = null;
            this.settingDataPointsSelected = null;
            this.settingsPopoverTitle = this.initedLabels.settingTitle;
            this.$emit('settings-popover-hide');
        },
        changeDataPoints() {
            this.settingsPopoverVisible = false;
            if (this.settingDataPointsSelected) {
                this.enabledDataPoints = [];
                dataPoints.forEach(d => {
                    if (this.settingDataPointsSelected[d.id]) {
                        this.enabledDataPoints.push(d.id);
                    }
                });
                const containerWidth = this.$refs.tableContainer.clientWidth;
                this.isMobile = this._isContainerVertical(containerWidth);
                this.$emit('settings-changed', this._loadStoreSettings());
            }
        },
        _loadSettingDataPoints() {
            return dataPoints.slice(1).map(item => {
                const name = this.initedLabels[item.id];
                return {
                    id: item.id,
                    name,
                    selected:
                        utils.inArray(this.enabledDataPoints, item.id) > -1,
                    visible: true
                };
            });
        },
        _loadStoreSettings() {
            return {
                dataPoints: this.enabledDataPoints
                    ? this.enabledDataPoints.join(',')
                    : ''
            };
        },
        _resizeObserverCallback({ entry }) {
            this.hideSettingsPopover();
            this.isMobile = this._isContainerVertical(entry.contentRect.width);
        },
        _isContainerVertical(width) {
            return width < 200 + this.enabledDataPoints.length * 45;
        }
    },
    data() {
        return {
            settingsPopoverVisible: false,
            settingsPopoverTriggerId: null,
            settingsPopoverNavSelected: undefined,
            settingDataPoints: undefined,
            settingDataPointsSelected: undefined,
            //  selectedColumn: undefined,
            selectedOrder: undefined,
            enabledDataPoints: undefined,
            isMobile: false
        };
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('sector-heatmap');

        this.enabledDataPoints =
            typeof this.watchedSettings.dataPoints === 'string'
                ? this.watchedSettings.dataPoints.split(',')
                : this.watchedSettings.dataPoints;
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-sector-heatmap';
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}
.#{$namespace} {
    &.markets-ui-container__fixed-height {
        .#{$namespace}-container {
            height: 100%;
            overflow: auto;
        }
    }
    .markets-ui-table__cell > div {
        min-width: 40px;
    }
    .markets-ui-table__cell.markets-ui-table__cell-sector > div {
        min-width: 200px;
    }
    &-sector-name {
        padding-left: $mds-space-half-x;
    }

    .mds-icon___markets {
        fill: $mds-text-color-primary;
    }
    .markets-ui-table__cell {
        padding: 1px;
        line-height: 18px;
    }
    .mds-icon___markets svg {
        margin-top: 2px;
    }
    &-color {
        &-cell {
            width: 100%;
            height: 100%;
            display: inline-block;
            padding: 5px;
        }
        &-negative {
            background-color: $mds-visualization-color-performance-negative;
            color: $mds-text-color-primary-on-dark;
        }
        &-negative-40 {
            background-color: tint(
                $mds-visualization-color-performance-negative,
                40%
            );
        }
        &-negative-70 {
            background-color: tint(
                $mds-visualization-color-performance-negative,
                70%
            );
        }
        &-base {
            background-color: $mds-visualization-color-performance-neutral;
        }
        &-positive-70 {
            background-color: tint(
                $mds-visualization-color-performance-positive,
                70%
            );
        }
        &-positive-40 {
            background-color: tint(
                $mds-visualization-color-performance-positive,
                40%
            );
        }
        &-positive {
            background-color: $mds-visualization-color-performance-positive;
            color: $mds-text-color-primary-on-dark;
        }
    }

    &-date-reference {
        font-weight: $mds-typography-font-weight-light;
        font-size: 12px;
        margin: 10px 5px;
    }

    &--dark-gray {
        .#{$namespace} {
            &-color {
                &-negative-40 {
                    color: $mds-color-black;
                }
                &-negative-70 {
                    color: $mds-color-black;
                }
                &-base {
                    color: $mds-color-black;
                }
                &-positive-70 {
                    color: $mds-color-black;
                }
                &-positive-40 {
                    color: $mds-color-black;
                }
            }
        }
        .markets-ui-table__table,
        .#{$namespace}-date-reference {
            color: $mds-text-color-primary-on-dark;
        }
        .mds-icon___markets {
            fill: $mds-text-color-primary-on-dark;
        }
    }
}
</style>
