import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
export const dataPoints = [
    {
        id: 'sector',
        dataType: 'custom',
        align: 'left',
        sortable: true,
        enabled: true,
        width: 400
    },
    {
        id: 'oneDay',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    },
    {
        id: 'oneWeek',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    },
    {
        id: 'oneMonth',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    },
    {
        id: 'threeMonth',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    },
    {
        id: 'oneYear',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    },
    {
        id: 'twoYear',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    },
    {
        id: 'threeYear',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    },
    {
        id: 'fiveYear',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    },
    {
        id: 'tenYear',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    },
    {
        id: 'fifteenYear',
        dataType: 'custom',
        align: 'right',
        sortable: true,
        width: 100
    }
];

const sectorSet = [
    {
        id: '111.10.MSTAR',
        secId: 'XIUSA0010F',
        label: 'USMarket',
        icon: ''
    },
    {
        id: '111.10.MSSEMCSS',
        secId: 'F00000LJF6',
        label: 'cyclical',
        icon: ''
    },
    {
        id: '111.10.MBMS',
        secId: 'F00000LJ8T',
        label: 'basicMaterials',
        icon: 'ip-sector-basic-materials'
    },
    {
        id: '111.10.MCCS',
        secId: 'F00000LJ8X',
        label: 'consumerCyclical',
        icon: 'ip-sector-consumer-cyclical'
    },
    {
        id: '111.10.MFSS',
        secId: 'F00000LJ95',
        label: 'financialServices',
        icon: 'ip-sector-financial-services'
    },
    {
        id: '111.10.MRETS',
        secId: 'F00000LJ9B',
        label: 'realEstate',
        icon: 'ip-sector-real-estate'
    },
    {
        id: '111.10.MSSEMDSS',
        secId: 'F00000LJGV',
        label: 'defensive',
        icon: ''
    },
    {
        id: '111.10.MCDS',
        secId: 'F00000LJ8Z',
        label: 'consumerDefensive',
        icon: 'ip-sector-consumer-defensive'
    },
    {
        id: '111.10.MHS',
        secId: 'F00000LJ97',
        label: 'healthcare',
        icon: 'ip-sector-healthcare'
    },
    {
        id: '111.10.MUS',
        secId: 'F00000LJ9F',
        label: 'utilities',
        icon: 'ip-sector-utilities'
    },
    {
        id: '111.10.MSSEMSSS',
        secId: 'F00000LJGX',
        label: 'sensitive',
        icon: ''
    },
    {
        id: '111.10.MCSS',
        secId: 'F00000LJ8V',
        label: 'communicationServices',
        icon: 'ip-sector-communication-services'
    },
    {
        id: '111.10.MES',
        secId: 'F00000LJ91',
        label: 'energy',
        icon: 'ip-sector-energy'
    },
    {
        id: '111.10.MIS',
        secId: 'F00000LJ99',
        label: 'industrials',
        icon: 'ip-sector-industrials'
    },
    {
        id: '111.10.MTS',
        secId: 'F00000LJ9D',
        label: 'technology',
        icon: 'ip-sector-technology'
    }
];
export function getStaticSecurities(instruments) {
    const array = instruments.split(',').map(instrument => {
        const { secId } = getSectorMeta(instrument) || {};
        return `${secId}|${instrument}`;
    });
    return array.join(',');
}
// security maybe secId|id or id
export function getSectorMeta(security) {
    const array = security.split('|');
    const id = array.length === 1 ? array[0] : array[1];
    return utils.find(sectorSet, sector => sector.id === id);
}
