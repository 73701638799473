<template>
    <SECTORHEATMAPUI
        ref="UI"
        :showLoading="showLoading"
        :skin="skin"
        :dataModel="dataModel"
        :errorCode="error.code"
        :symbol="watchedSymbol"
        :settings="settings"
        :labels="mergeLabels(defaultConfig.labels)"
        :formatter="formatter"
        @settings-changed="changeSettings"
        @settings-popover-hide="hideSettingsPopover"
    ></SECTORHEATMAPUI>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import SECTORHEATMAPUI from './sector-heatmap-ui';
import labels from './assets/labels.json';
import { getStaticSecurities } from './metadata/datapoint';
const { utils, mixins } = mwcMarketsCore;
const COMPONENT_NAME = 'mwc-markets-sector-heatmap';
export default {
    name: COMPONENT_NAME,
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    components: {
        SECTORHEATMAPUI
    },
    data() {
        return {
            name: COMPONENT_NAME,
            defaultConfig: {
                settings: {
                    autoHeight: true,
                    showHeader: false,
                    showSetting: false,
                    showBorder: false,
                    showLoading: false,
                    showSectorIcon: true,
                    showOneDayAsIntraday: false,
                    dataType: 'pull',
                    languageId: 'en-US',
                    symbol:
                        '111.10.MBMS,111.10.MCCS,111.10.MFSS,111.10.MRETS,111.10.MCDS,111.10.MHS,111.10.MUS,111.10.MCSS,111.10.MES,111.10.MIS,111.10.MTS',
                    dataPoints:
                        'oneDay,oneWeek,oneMonth,threeMonth,oneYear,threeYear',
                    updateLastAccessTime: true
                },
                labels: labels,
                format: {
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    },
                    date: {
                        dataType: 'date',
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                    },
                    dateTime: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour12: true
                    }
                },
                intlNamespace: COMPONENT_NAME
            },
            dataModel: [],
            watchedSymbol: '',
            showLoading: false
        };
    },
    created() {
        // init
        this.initialized = false;
        this.namespace = utils.namespace('sector-heatmap');
        this.watchedSymbol = getStaticSecurities(this.settings.symbol);

        this.subscribeList = this.settings.symbol.split(',');
        this.changeDataType(this.settings.dataType);
        this.currentDataType = this.settings.dataType;
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el) && !this.initialized) {
            this.initialize();
        }
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        this._checkAndUnSubscribe(this.currentDataType, this.subscribeList);
    },
    methods: {
        initialize() {
            this.initialized = true;
            if (this.watchedSymbol) {
                this.initSnapshot();
                if (
                    utils.inArray(
                        this.settings.dataPoints.split(','),
                        'oneDay'
                    ) >= 0
                ) {
                    this._checkAndSubscribe(
                        this.currentDataType,
                        this.subscribeList
                    );
                }
            }
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                } else {
                    this._checkAndSubscribe(
                        this.currentDataType,
                        this.subscribeList
                    );
                }
            } else {
                if (!this.targetVisibility) {
                    this._checkAndUnSubscribe(
                        this.currentDataType,
                        this.subscribeList
                    );
                }
                this.targetVisibility = false;
            }
        },
        _checkAndSubscribe(dataType, subscribeList) {
            if (this.settings.showOneDayAsIntraday && !this.isSubscribing) {
                this._subscribe(dataType, subscribeList);
            }
        },
        _checkAndUnSubscribe(dataType, subscribeList) {
            if (this.settings.showOneDayAsIntraday && this.isSubscribing) {
                this._unsubscribe(this.currentDataType, this.subscribeList);
            }
        },
        _subscribe(dataType, symbols) {
            if (
                dataType &&
                symbols &&
                this[`${dataType}SubscribePromise`] &&
                this.initialized
            ) {
                this.isSubscribing = true;
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.subscribe(symbols, this.subscribeListener, {
                            updateLastAccessTime: this.settings
                                .updateLastAccessTime
                        });
                    })
                    .catch(e => {});
            }
        },
        _unsubscribe(dataType, symbols) {
            if (dataType && symbols && this[`${dataType}SubscribePromise`]) {
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.unsubscribe(symbols, this.subscribeListener);
                    })
                    .catch(e => {});
                this.isSubscribing = false;
            }
        },
        changeDataType(value) {
            if (!this.settings.showOneDayAsIntraday || !this.mktdata) {
                return;
            }
            const oldValue = this.currentDataType;
            if (value === 'stream') {
                this.currentDataType = value;
            } else {
                this.currentDataType = 'pull';
            }
            if (!this[`${this.currentDataType}SubscribePromise`]) {
                this[`${this.currentDataType}SubscribePromise`] = this.mktdata[
                    this.currentDataType
                ]();
            }
            if (oldValue) {
                this._unsubscribe(oldValue, this.subscribeList);
            }
            this._subscribe(this.currentDataType, this.subscribeList);
        },
        initSnapshot() {
            if (this.initialized && this.mktdata) {
                this.showLoading = true;
                const { showOneDayAsIntraday } = this.settings;
                const promiseList = [];
                // default symbol such as F00000LJ8T|33.10.!MBMS
                let symbolList = this.watchedSymbol.split(',');
                if (showOneDayAsIntraday) {
                    // for intraday, change to use instrument like 33.10.XXX to go id service
                    symbolList = this.subscribeList;
                    promiseList.push(
                        this.mktdata
                            .quotes({
                                securities: symbolList
                            })
                            .then(quoteData => {
                                // if there is no 33 in admin, fill data to go to next process
                                if (quoteData.length === 0) {
                                    return symbolList.map(security => {
                                        return {
                                            security,
                                            quotes: []
                                        };
                                    });
                                }
                                return quoteData;
                            })
                            .catch(() => {
                                return Promise.resolve([]);
                            }),
                        this.mktdata.securities(symbolList).catch(() => {
                            return Promise.resolve([]);
                        })
                    );
                }
                promiseList.push(
                    this.mktdata
                        .staticData({
                            securities: symbolList
                        })
                        .catch(() => {
                            return Promise.resolve([]);
                        })
                );
                return Promise.all(promiseList)
                    .then(res => {
                        let quotes, securityData;
                        let statics = res[0];
                        if (showOneDayAsIntraday) {
                            [quotes, securityData, statics] = res;
                        }
                        symbolList.forEach(security => {
                            // security will be such as F00000LJ8T|33.10.!MBMS or 33.10.!MBMS, so need extract instrument 33.10.XXX for mapping
                            const instrument =
                                security.split('|')[1] || security;
                            const st = utils.find(
                                statics,
                                s => s.security === security
                            );
                            let qt;
                            if (quotes) {
                                qt = utils.find(
                                    quotes,
                                    q => q.security === instrument
                                );
                            }
                            let sec;
                            if (securityData) {
                                sec = utils.find(
                                    securityData,
                                    item => item.queryKey === instrument
                                );
                            }
                            this._setDataModel(st, qt, sec);
                        });
                        this.renderCompleted = true;
                    })
                    .catch(e => {
                        this._setError(this.ERROR_TYPES.NODATA);
                    });
            } else {
                return Promise.reject();
            }
        },
        changeSettings(val) {
            if (this.settings.showOneDayAsIntraday) {
                const arr = val.dataPoints.split(',');
                const found = utils.inArray(arr, 'oneDay');
                if (found > -1 && !this.isSubscribing) {
                    this._subscribe(this.currentDataType, this.subscribeList);
                } else if (found === -1 && this.isSubscribing) {
                    this._unsubscribe(this.currentDataType, this.subscribeList);
                }
            }
            this.$emit('settings-changed', val);
        },
        updateData(security, updates) {
            // security is the instrument like 33.10.XXX
            const idx = utils.findIndex(
                this.dataModel,
                f =>
                    f.queryKey.split('|')[1] === security ||
                    f.queryKey === security // queryKey is like F00000LJ8T|33.10.!MBMS or 33.10.!MBMS
            );
            if (idx < 0) {
                return;
            }
            const mapUpdatesData = this.filterData(updates);
            if (Object.keys(mapUpdatesData).length) {
                const securityItem = utils.extend(
                    {},
                    this.dataModel[idx],
                    mapUpdatesData
                );
                this.$set(this.dataModel, idx, securityItem);
            }
        },
        filterData(quotes) {
            const map = {};
            if (quotes && quotes.length) {
                quotes.forEach(q => {
                    let value = q.value;
                    if (q.name === 'tradeDateTime') {
                        map['lastTradeDateTime'] = value;
                    } else if (q.name === 'chg%') {
                        map['oneDay'] = parseFloat(value);
                    }
                });
            }
            return map;
        },
        _setDataModel(staticData, quoteData, secData) {
            this.showLoading = false;
            const security = staticData.security;
            const updates = staticData.statics;
            const _dataModel = {};
            const dataObject = {};
            updates.forEach(item => {
                const match = item.name.match(/(\w+)Return$/);
                if (match) {
                    dataObject[match[1]] = +item.value;
                } else {
                    dataObject[item.name] = item.value;
                }
            });
            _dataModel[security] = dataObject;
            if (quoteData && secData) {
                const chgp = utils.find(
                    quoteData.quotes,
                    q => q.name === 'chg%'
                );
                _dataModel[security].oneDay = chgp
                    ? parseFloat(chgp.value)
                    : undefined;
                const tradeDateTime = utils.find(
                    quoteData.quotes,
                    q => q.name === 'tradeDateTime'
                );
                if (tradeDateTime) {
                    _dataModel[security]['lastTradeDateTime'] =
                        tradeDateTime.value;
                    // if there is no tradeDateTime, no need timezoneAbbreviation
                    _dataModel[security]['timezoneAbbreviation'] =
                        secData.timezoneAbbreviation;
                }
            }
            _dataModel[security]['queryKey'] = security;
            this.dataModel.push(_dataModel[security]);
        },
        _setError(error) {
            this.error = error;
            this.showLoading = false;
        }
    }
};
</script>
